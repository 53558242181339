import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 获取logo
export function getLogo (params) {
  return getRequest('/cs/config/get-logo', params)
}
// PC端logo
export function saveLogo (params) {
  return postBodyRequest('/cs/config/save-logo', params)
}
// 上传public文件
export function uploadLogo (type,params) {
  return postBodyRequest(`/cs/file/upload/${type}/public`, params)
}

// 收款账户新增
export function collectionAdd (params) {
  return postBodyRequest(`/cs/finance/collection/add`, params)
}
// 收款账户列表
export function collectionList (params) {
  return postBodyRequest(`/cs/finance/collection/list`, params)
}
// 删除收款账户
export function collectionDel (id) {
  return getRequest(`/cs/finance/collection/delete?id=${id}`)
}