<template>
  <div>
    <div :class="changeSty ? 'chengeStyle' : ''">
      <slot name="head"></slot>
      <a-form ref="formRef" :model="fromOptions" :rules="rulesOpts">
        <a-form-item  v-for="item in fromList" :key="item.id" :name="item.name">
          <template #label>
            {{ item.label }}<span v-if="item.tips" class="fz-12 text-title">{{ item.tips }}</span>
          </template>
          <template v-if="item.type === 'input'">
            <a-input :style="{ width: item.width ? item.width : '33%' }" :disabled="item.disabled" v-model:value="fromOptions[item.prop]" :suffix="item.unit" :placeholder="item.placeholder" />
          </template>
          <template v-if="item.type === 'inputNumber'">
            <a-input-number :style="{ width: item.width ? item.width : '33%' }" v-model:value="fromOptions[item.prop]" :suffix="item.unit" :placeholder="item.placeholder" />
          </template>
          <template v-if="item.type === 'cascader'">
            <a-cascader :style="{ width: item.width ? item.width : '33%' }" :options="item.opt" :show-search="{ filter }":filterOption="filterOpt" :field-names="{ value: item.valueType || 'value' }" v-model:value="fromOptions[item.prop]" :suffix="item.unit" :placeholder="item.placeholder" />
          </template>
          <!-- 单选 -->
          <template v-if="item.type === 'select'">
            <a-select :style="{ width: item.width ? item.width : '33%' }" show-search  :filterOption="filterOpt" :disabled="item.disabled"  v-model:value="fromOptions[item.prop]" :placeholder="item.placeholder">
              <a-select-option v-for="opt in item.opt" :key="opt.value" :label="opt.label" :value="opt.value">{{ opt.label }}</a-select-option>
            </a-select>
          </template>
          <!-- 地址 -->
          <template v-if="item.type == 'address'">
            <a-cascader v-model:value="fromOptions[item.prop]" :change-on-select="item.changeOn" :options="$store.state.app.cityDict" show-search @change="setStartAddress" :placeholder="item.placeholder" />
          </template>
          <!-- 时间选择 -->
          <template v-if="item.type == 'selectTime'">
            <a-date-picker :style="{ width: item.width ? item.width : '33%' }" v-model:value="fromOptions[item.prop]" :placeholder="item.placeholder" />
          </template>
          <!-- 搜索框 -->
          <div v-if="item.type === 'selectSearch'">
            <div :style="{ width: item.width ? item.width : '33%' }">
              <a-select  :filter-option="false" :options="item.opt" :disabled="item.disabled" @select="select" v-model:value="fromOptions[item.prop]" show-search :placeholder="item.placeholder" :not-found-content="null" @search="search">
              </a-select>
            </div>
          </div>
          <!-- 搜索框多选 -->
          <div v-if="item.type === 'selectSearchMore'">
            <div :style="{ width: item.width ? item.width : '33%' }">
              <a-select :filter-option="false" v-model:value="fromOptions[item.prop]" @change="selectMore" :placeholder="item.placeholder" mode="multiple" :not-found-content="null" @search="searchMore">
                <a-select-option v-for="opt in item.opt" :key="opt.label" :title="opt.label" :value="opt.value">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
          </div>
          <!-- 多选 -->
          <div v-if="item.type === 'tag'">
            <div :style="{ width: item.width ? item.width : '33%' }">
              <div class="flex tagBox al-center">
                <div class="flex  wrap">
                  <div class="tag" v-for="(opt, idx) in item.opt" :key="opt.id">
                    {{ opt }}
                    <CloseOutlined style="font-size: 12px" @click="addClose(item, idx)" />
                  </div>
                </div>
                <div>
                  <a-input v-model:value="fromOptions[item.prop]" :placeholder="item.placeholder" @keyup.enter.native="handleChange(item, fromOptions[item.prop])" />
                </div>
              </div>
            </div>
          </div>
          <!-- 根据第一个选择第二个 -->
          <div v-if="item.type === 'mulSelect'" class="flex">
            <div :style="{ width: item.width ? item.width : '33%' }">
              <a-select v-model:value="fromOptions[item.prop1]" @change="seleAdd1(fromOptions[item.prop1], item,index)" :placeholder="item.placeholder1">
                <a-select-option v-for="opt in item.opt1" :key="opt.id" :value="opt.value">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
            <div :style="{ width: item.width ? item.width : '33%' }" class="m-l1">
              <a-select @change="seleAdd2(fromOptions[item.prop2], item)" v-model:value="fromOptions[item.prop2]" v-if="fromOptions[item.prop1]&&item.prop2" :placeholder="item.placeholder2">
                <a-select-option v-for="opt in item.opt2" :key="opt.id" :value="opt.value">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
            <div :style="{ width: item.width ? item.width : '33%' }" class="m-l1">
              <a-select v-model:value="fromOptions[item.prop3]" v-if="fromOptions[item.prop2]&&item.prop3" :placeholder="item.placeholder3">
                <a-select-option v-for="opt in item.opt3" :key="opt.id" :value="opt.value">{{ opt.label }}</a-select-option>
              </a-select>
            </div>
          </div>
          <template v-if="item.type === 'textarea'">
            <a-textarea class="textarea" :maxlength="item.max" :rows="item.rows || 4" v-model:value="fromOptions[item.prop]"  :placeholder="item.placeholder"/>
          </template>
          <template v-if="item.type === 'switch'">
            <a-switch v-model:checked="fromOptions[item.prop]" :checkedValue="item.checkedValue" :unCheckedValue="item.unCheckedValue" />
          </template>
          <template v-if="item.type === 'radio'">
            <a-radio-group v-model:value="fromOptions[item.prop]" :options="item.opt" />
          </template>
          <div v-if="item.type === 'upload'">
            <ly-upload ref="uploadRef" :maxMun="maxMun" @fileStatus="fileStatus"></ly-upload>
          </div>
          <div v-if="item.type === 'uploadList'">
            <ly-uploadList ref="uploadRef" :maxMun="maxMun" @fileStatus="fileStatus"></ly-uploadList>
          </div>
        </a-form-item>
      </a-form>
      <slot name="custom"></slot>
    </div>
    <div class="flex ju-end m-t2">
      <ly-button type="default" class="m-r1" @click="cancel">取消</ly-button>
      <ly-button @click="submit" :loading="fileLoading" type="primary">确定</ly-button>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, nextTick } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { transitionTimeYMD } from '@/utils/util'
const props = defineProps({
  fromList: {
    type: Array,
    default: [],
  },
  changeSty: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "40%",
  },
  destroyOnClose: {
    type: Boolean,
    default: false,
  },
  isBtn: {
    type: Boolean,
    default: false,
  },
  labelCol: {
    type: Object,
    default: { span: 2 },
  },
  maxMun:{
    type: Number,
    default: 5,
  }

});
const fileLoading = ref(false)
const previewVisible = ref(false)
const previewImage = ref("")
const emit = defineEmits([
  "getVal",
  "seleChange1",
  "seleChange2",
  "handleChange",
  "addClose",
  "search",
  "select",
  "searchMore",
  "selectMore",
  "fileStatus",
  "cancel"
]);
const cancel = () => {
  emit('cancel')
}
const fileStatus = (status) => {
  emit('fileStatus', status)
  fileLoading.value = status
}
const seleAdd1 = (val, item) => {
  fromOptions.value[item.prop2] = null;
  emit("seleChange1", val, item);
};
const seleAdd2 = (val, item) => {
  fromOptions.value[item.prop3] = null;
  emit("seleChange2", val);
}
const uploadRef = ref(null)
const formRef = ref(null);
const submit = () => {
  formRef.value
    .validate()
    .then(() => {
      emit("getVal", fromOptions.value, uploadRef.value?.state.fileIds);
    })
};
//验证规则数据处理
const fromOptions = ref({});
const rulesOpts = ref({});
const setValue = () => {
  let option = {};
  let rules = {};
  props.fromList.map((item) => {
    if (item.prop1) {
      rules[item.prop1] = item.rules1;
      option[item.prop1] = item.value1;
    }
    if (item.prop2) {
      rules[item.prop2] = item.rules2;
      option[item.prop2] = item.value2;
    }
    rules[item.prop] = item.rules;
    option[item.prop] = item.value;

  });
  fromOptions.value = option;
  rulesOpts.value = rules;
  if (option.upload) {
    nextTick(() => {
      uploadRef.value.state.fileIds = option.upload
    })
  }
  console.log(option);
};
// 选择的值
const select = (val, i) => {
  emit("select", i);
};
//多选的值
const selectMore = (val, i) => {
  emit("selectMore", i);
};
//搜索框单选
const search = (val) => {
  emit("search", val);
};
//搜索框多选
const searchMore = (val) => {
  emit("searchMore", val);
};
//多选删除
const addClose = (item, idx) => {
  emit("addClose", item, idx);
};
// 多选输入框改变时间
const handleChange = (item, val) => {
  emit("handleChange", item, val);
  fromOptions.value[item.prop] = "";
};
const filterOpt = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

defineExpose({
  setValue,
  fromOptions,
  submit,
  fileLoading
})

onMounted(() => {
  setValue();
});
</script>

<style lang="less" scoped>
.chengeStyle {
  :deep(.ant-form-item-label) {
    width: 100%;
    text-align: left;
  }
  :deep(.ant-form-item) {
    margin-bottom: 5px;
  }
}

.tagBox {
  width: 100%;
  min-height: 35px;
  border: 1px solid #d9d9d9;
  padding: 5px 0;
  flex-wrap: wrap;
  .tag {
    background: #f2f2f2;
    padding: 2px 8px;
    margin-left: 5px;
    height: 25px;
    margin-bottom: 5px;
  }
  :deep(.ant-input) {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>
