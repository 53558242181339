
const staticConfig = {
  state: {
    scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'// at.alicdn.com/t/font_2734322_d05d10c0f2d.js'
  },

  mutations: {
  },
  actions: {

  }
}
export default staticConfig
