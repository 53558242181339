<template>
<div>
  <div style="border: 1px solid #ccc;z-index:9999" >
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor :style="{height:height}" style="overflow-y: hidden;" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
      </div>
</div> 
</template>

<script setup>
import { ref,onBeforeUnmount, shallowRef } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

const props = defineProps({
  height: {
    type: String,
    default: '400px'
  },
})
const mode = ref('simple')
const editorConfig = ref({
  placeholder: '请输入内容...',
})
const editorRef = shallowRef()
const valueHtml = ref('')
const toolbarConfig = {}
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}
defineExpose({
  valueHtml,
  editorRef
})


</script>

<style lang="less" scoped>

</style>