<template>
  <div>
    <a-table @expand="expand" @change="pageChange" :expandedRowKeys="expandedRowKeys" 
    :row-selection="rowSelectionShow?{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }:null" 
    :columns="columns" :data-source="data" :bordered="bordered" :row-key="record => record[rowKey]" 
    :size="size" 
    :pagination="pagination || false" 
    :customRow="customRow"
    :scroll="scroll"
    :loading="loading">
      <template #[item]="scope" v-for="item in renderArr">
        <slot :name="item" :scope="scope.record" v-bind="scope || {}"></slot>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { onMounted, ref, useSlots } from 'vue'
const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  data: {
    type: Array,
    required: true
  },
  size: {
    type: String,
    default: 'default'
  },
  pagination: {
    type: Object,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  rowKey: {
    type: String,
    default: 'id'
  },
  rowSelectionShow: {
    type: Boolean,
    default: false
  },
  bordered: {
    type: Boolean,
    default: true
  },
  remakeKey:{
    type: String,
    default: 'id'
  },
  scroll:{
    type: Object,
    default: {}
  },
  customRow:{
    type:Function,
    default:()=>{}
  }
})
const selectedRowKeys = ref([])
const expandedRowKeys = ref([])
const slots = useSlots();
const renderArr = Object.keys(slots);
const emit = defineEmits(['onChange','pageChange'])
const onSelectChange = (selectedRowKey, selectedRows) => {
  selectedRowKeys.value = selectedRowKey
  emit('onChange', selectedRowKey, selectedRows)
}
// function getCheckboxProps:(record) => ({
//       record.name === 'Disabled User'
// })
const pageChange = (e) => {
 emit('pageChange', e)
}
//展开图标
const expand = (item, val) => {
  if (item) {
        expandedRowKeys.value.push(val[props.remakeKey])
        emit('reqGetList',val)
      } else {
        let  Keys =   expandedRowKeys.value.filter(RowKey => RowKey !== val[props.remakeKey])
        expandedRowKeys.value = Keys
      }
}
defineExpose({
  selectedRowKeys,
  expandedRowKeys
})    
</script>

<style lang="less" scoped>
:deep(.ant-table-tbody) {
  tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background-color: #edf1ff;
    // color: #365dd2;
  }
}
:deep(.ant-table-thead tr th) {
  background: #ebebeb !important;
  color: #191919;
}
:deep(.ant-pagination-item-active) {
  border-color: #365dd2;
}
// 鼠标hover时候的颜色
:deep(.ant-checkbox-wrapper:hover){
  .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner{
   border: 1px solid #204AC4 !important;
}

} 
// 设置默认的颜色
:deep(.ant-checkbox){
  .ant-checkbox-inner{
    border: 1px solid #d9d9d9;
    background-color: transparent;
  }
} 
// 设置选中的颜色
:deep(.ant-checkbox-checked) {
  .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color:#204AC4;
    border: 2px solid #204AC4;
}
}
:deep(.ant-checkbox-indeterminate .ant-checkbox-inner::after){
 
    background-color: #204AC4;
 
}
:deep(.ant-table-row-expand-icon:focus, ){
  color: #204AC4;
}
:deep( .ant-table-row-expand-icon:hover){
  color: #204AC4;
}
</style>