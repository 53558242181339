
<template>
  <div class="ant-suy-tabs layout-tabs-fix">
    <a-tabs type="editable-card" :activeKey="currentPageName" @edit="closeTab" @change="changeTab" hideAdd>
      <a-tab-pane v-for="tab in pageTabs" :key="tab.name" :tab="$t(tab.meta.title)" :closable="tab.name !== 'home'" />
      <template #tabBarExtraContent>
        <a-dropdown>
          <div class="ant-suy-tabs-close">
            <DownOutlined style="font-size:14px;" />
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="closeLeftTab">
                <span>{{ $t('tabsBar.closeLeft') }}</span>
              </a-menu-item>
              <a-menu-item @click="closeRightTab">
                <ArrowRightOutlined />
                <span>{{ $t('tabsBar.closeRight') }}</span>
              </a-menu-item>
              <a-menu-item @click="closeOtherTab">
                <CloseOutlined />
                <span>{{ $t('tabsBar.closeOther') }}</span>
              </a-menu-item>
              <a-menu-item @click="closeAllTab">
                <CloseCircleOutlined />
                <span>{{ $t('tabsBar.closeAll') }}</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-tabs>
  </div>
</template>
<script>
// import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  DownOutlined,
  // ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  CloseCircleOutlined
} from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { computed } from '@vue/runtime-core'
export default {
  props: {
    pageTabs: Array
  },
  setup (props, { emit }) {
    // console.log('props', props)
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      currentPageName: computed(() => router.currentRoute.value.name)
    })
    // const { name, params, query, meta } = router.currentRoute.value
    // onMounted(() => {
    //   store.commit('addTab', {
    //     route: { name, params, query, meta }
    //   })
    // })

    const changeTab = activeKey => {
      router.push(props.pageTabs.find(tab => tab.name === activeKey))
    }

    const closeTab = (targetKey, action) => {
      let pageOpenedList = store.state.app.tabs
      let lastPageObj = pageOpenedList[0]
      if (state.currentPageName === targetKey) {
        const len = pageOpenedList.length
        for (let i = 1; i < len; i++) {
          if (pageOpenedList[i].name === targetKey) {
            if (i < len - 1) {
              lastPageObj = pageOpenedList[i + 1]
            } else {
              lastPageObj = pageOpenedList[i - 1]
            }
            break
          }
        }
      }
      store.commit('CLOSE_TAB', targetKey)
      pageOpenedList = store.state.app.tabs
      localStorage.pageOpenedList = JSON.stringify(pageOpenedList)
      if (state.currentPageName === targetKey) {
        router.push(lastPageObj)
      }
    }

    const closeLeftTab = ({ key, domEvent }) => {
      store.commit('CLOSE_LEFT_TAB', router.currentRoute.value.name)
    }

    const closeRightTab = ({ key, domEvent }) => {
      store.commit('CLOSE_RIGHT_TAB', router.currentRoute.value.name)
    }
    const closeOtherTab = ({ key, domEvent }) => {
      store.commit('CLOSE_OTHER_TAB', router.currentRoute.value.name)
    }

    const closeAllTab = () => {
      store.commit('CLOSE_ALL_TAB', router.currentRoute.value.name)
    }
    // onMounted(test())

    return {
      ...toRefs(state),
      changeTab,
      closeTab,
      closeLeftTab,
      closeRightTab,
      closeOtherTab,
      closeAllTab
    }
  },
  components: {
    DownOutlined,
    // ArrowLeftOutlined,
    ArrowRightOutlined,
    CloseOutlined,
    CloseCircleOutlined
  }
}
</script>
<style lang="less" scoped>
.ant-suy-tabs {
  background-color: #f0f2f5;
  transition: all 0.2s ease-in-out;
  :deep(.ant-tabs-card) {
    .ant-tabs-card-bar {
      padding: 6px 0;
      margin: 0 12px 0 12px;
      border: 0;

      .ant-tabs-nav-container {
        height: 32px;
        margin-bottom: 0px;
      }

      .ant-tabs-tab {
        height: 32px;
        line-height: 32px;
        background: #fff;
        border-radius: 3px;
        border: none;
        text-align: center;
        margin-right: 6px;
        color: #808695;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #40a9ff;
        }

        .ant-tabs-close-x {
          height: 16px;
          transform: translateZ(0);
          margin-right: -6px;
          font-size: 14px;
        }
      }

      .ant-tabs-tab-active {
        height: 32px;
        background: #fff;
        color: #2d8cf0;
      }
    }
  }

  &-close {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;
  }
}
:deep( .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active){
  color: #365DD2 !important;
}
:deep( .ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover){
  color: #365DD2 !important;
}
</style>
