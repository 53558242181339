<style lang="less" scoped>
@import "./BasicLayout.less";
</style>
<template>
  <div>
    <a-layout class="basic-layout">
      <div
        v-if="siderFixed"
        class="fixed-stuff"
        :style="{ width: collapsed ? '80px' : '200px', overflow: 'hidden' }"
      />
      <a-layout-sider
        v-model:collapsed="collapsed"
        :class="[
          'layout-sider',
          { 'layout-sider-dark': siderTheme === 'dark' },
          { 'layout-sider-light': siderTheme === 'light' },
          'layout-sider-side',
          { 'layout-sider-fixed': siderFixed },
        ]"
        :theme="siderTheme"
        width="200" 
        collapsedWidth="80"
        :trigger="null"
        style="overflow: hidden"
        collapsible
      >
        <div
          class="layout-sider-logo"
          :class="siderTheme === 'dark' ? 'layout-sider-logo-dark' : ''"
        >
          <a href="/" target="_self" class="i-link i-link-color" >
            <img v-show="!collapsed" style="width:90%" :src="expandLogo" key="max-logo" />
            <img v-show="collapsed" style="height:70%" :src="shrinkLogo" key="min-logo" />
          </a>
        </div>
        <!-- 主页导航栏 -->
        <div style="flex: 1 1 0%; overflow: hidden auto">
          <a-menu v-model:selectedKeys="selectedMenu" :theme="siderTheme" mode="inline">
            <template v-for="menu in menus">
              <a-menu-item v-if="menu.only" :key="menu.children[0].name">
                <template #icon>
                  <span style="color: #fff; font-size: 20px"
                    ><svg class="icon" aria-hidden="true">
                      <use :href="`#hy-${menu.meta.icon}`"></use></svg
                  ></span>
                </template>
                <router-link :to="menu.children[0].path">{{ $t(menu.meta.title) }}</router-link>
              </a-menu-item>
              <a-sub-menu v-if="menu.children&&!menu.only" :key="menu.name">
                <template #icon>
                  <span style="color: #fff; font-size: 20px"
                    ><svg class="icon" aria-hidden="true">
                      <use :href="`#hy-${menu.meta.icon}`"></use></svg
                  ></span>
                </template>
                <template #title>
                  {{ $t(menu.meta.title) }}
                </template>
                <a-menu-item v-for="itemMenu in menu.children" :key="itemMenu.name">
                  <router-link :to="itemMenu.path">{{
                    $t(itemMenu.meta.title)
                  }}</router-link>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header
          :class="[
            'layout-header',
            'layout-header-fix',
            { 'layout-header-color-dark': headerTheme === 'dark' },
            { 'layout-header-color-light': headerTheme === 'light' },
          ]"
          :style="{ width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }"
        >
          <span class="layout-header-trigger" @click="() => (collapsed = !collapsed)">
            <menu-unfold-outlined v-if="collapsed" />
            <menu-fold-outlined v-else />
          </span>
          <div class="layout-header-right m-r4" style="display: flex">
            <!-- <full-screen  v-model:value="fullscreen" class="layout-header-trigger" /> -->
            <div>{{ $store.getters.carrierName }}</div>
            <a-dropdown v-if="currentUser && currentUser.name">
              <span class="layout-header-trigger">
                <a-avatar
                  class="layout-header-avatar"
                  src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                />
                <span class="layout-header-name">{{ currentUser.name }}</span>
              </span>
              <template v-slot:overlay>
                <a-menu :selected-keys="[]">
                  <a-menu-item key="center" @click="handleToCenter" disabled>
                    <UserOutlined />
                    {{ $t("menu.account.center") }}
                  </a-menu-item>
                  <a-menu-item key="settings" @click="handleToSettings" disabled>
                    <SettingOutlined />
                    {{ $t("menu.account.settings") }}
                  </a-menu-item>
                  <a-menu-item key="settings" @click="handleChangePass">
                    <KeyOutlined />
                    修改密码
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item @click="handleLogout">
                    <ExportOutlined />
                    {{ $t("menu.account.logout") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
       
            
          </div>
        </a-layout-header>
        <a-layout-content
          class="basic-layout-content basic-layout-content-fix-with-header"
        >
          <MenuTabs
            ref="closeAllTab"
            :pageTabs="pageTabs"
            :style="{ width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)' }"
          />
          <div class="ant-page-container">
            <Suspense>
              <template #default>
                <router-view v-slot="{ Component }">
                  <keep-alive>
                    <component
                      :is="Component"
                      :key="router.currentRoute.value.meta.name"
                      v-if="router.currentRoute.value.meta.keepAlive === true"
                    />
                  </keep-alive>
                  <component
                    :is="Component"
                    :key="router.currentRoute.value.meta.name"
                    v-if="router.currentRoute.value.meta.keepAlive === false"
                  />
                </router-view>
              </template>
              <template #fallback> Loading... </template>
            </Suspense>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <ChangePass v-if="visible" v-model:value="visible" />

  </div>
</template>
<script>
import { toRefs, onMounted, computed, watch, reactive, ref, h } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import storage from 'store'
import Cookies from 'js-cookie' 
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  ExportOutlined,
  DownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  KeyOutlined,
  ShopOutlined,
  ContactsOutlined,
  ClusterOutlined, 
  DashboardOutlined,
  createFromIconfontCN, 
  
} from '@ant-design/icons-vue'
import { Modal, notification, Button } from 'ant-design-vue'
import { orgList } from '@/api/system/organzition'
import SelectLang from '@/components/SelectLang'
import ChangePass from '@/views/components/change-pass'   
import FullScreen from './components/Fullscreen'
import MenuTabs from './components/MenuTabs'

const IconFont = createFromIconfontCN({  
  scriptUrl: '//at.alicdn.com/t/c/font_2734322_olh9vocrojb.js'
})
export default { 
  setup () {
    let closeAllTab = ref(null)
    const store = useStore()
    const router = useRouter()
    const menus = computed(() => store.getters.asyncRouters)
    const selectedMenu = computed(() => [router.currentRoute.value.name])
    const pageTabs = computed(() => store.getters.tabs)
    const gpsSearchRef = ref(null)
    let systemDomain = ''
   const newsVisible = ref(false)
    const shallowState = reactive({ 
      detailDataShow: false,
      siderFixed: true,
      vinNoIndex: 1000,
      transportIndex: 1000,
      shopOperateIndex: 1000,
      GPSindex: 1000,
      siderTheme: 'dark',
      routerPath: '',
      headerTheme: 'light',
      collapsed: false,
      fullscreen: false,
      orderIdList: [],
      orderVehicleIdList: [],
      detailData: { orderId: '', orderVehicleId: '' },
      currentUser: { name: store.getters.userName },
      expandLogo:store.getters.logos.expandLogo?.url,
      shrinkLogo:store.getters.logos.shrinkLogo?.url,
      visible: false,
      vinNoShow: false,
      transportShow: false,
      shopOperateShow: false,
      vinNo: ref(null),
      transport: ref(null),
      repositoryMadalShow:false
    })
    const countNum = ref(0)
    const routerMenu = (menu) => {
      if (menu.jump) {
        shallowState.routerPath = menu.path
        // Cookies.remove('accessToken')
        // Cookies.set('accessToken', storage.get('accessToken'), '.56pai.cn')
        const H = process.env.NODE_ENV === 'prod' ? 'https://' : 'http://'
        systemDomain = store.getters.uat.filter(
          (item) => item.systemType.value === menu.systemType
        )[0].systemDomain
        window.open(H + systemDomain + '/#' + menu.path, '_parent')
      }
    }
   
    const openNews = () => {
      newsVisible.value = true
    }
    const newChange = () => {
    }
     
    watch(() => store.getters.logos,(val) => {
     shallowState.expandLogo = val.expandLogo?.url
     shallowState.shrinkLogo = val.shrinkLogo?.url
    })
    onMounted(() => {
      notification.config({
        duration: 3
      })
      loadData()
      closeAllTab = closeAllTab.value.closeAllTab
      store.commit('setBreadCrumb', router.currentRoute.value)
    })
    const loadData = () => {
      orgList({ orgType: 1 }).then((res) => {
        if (res.code === 10000) store.commit('ORG_TYPE_LIST', res.data)
      })
    }
    const cachePage = computed(() => store.state.app.cachePage)
    const handleToCenter = () => {
      // Todo
    }
    const handleChangePass = () => {
      shallowState.visible = true
    }
    const handleToSettings = () => {}
    
 
    watch(
      router.currentRoute,
      (newRoute, oldRoute) => {
        const { name, query, params, meta } = newRoute
        store.commit('ADD_TAB', { name, query, params, meta })
        store.commit('setBreadCrumb', newRoute)
        // console.log(cachePage) // store.state.app.
      },
      { deep: true }
    )

    const handleLogout = (e) => {
      Modal.confirm({
        title: '信息',
        content: '你确定要退出吗',
        onOk: async () => {
          return store.dispatch('Logout').then(() => {
            storage.remove('accessToken')
            Cookies.remove('accessToken')
            router.push({ name: 'login' })
            closeAllTab()
            location.reload()
          })
        },
        onCancel () {}
      })
    }
    const vinnoStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.vinNoShow = e
      }
    }
    const transportStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.transportShow = e
      }
    }
    const shopStatusChange = (e) => {
      if (e !== undefined) {
        shallowState.shopOperateShow = e
      }
    }
    return {
      ...toRefs(shallowState),
      closeAllTab, // ref事件传递return
      gpsSearchRef,
      menus,
      cachePage,
      selectedMenu,
      IconFont,
      router,
      vinnoStatusChange,
      shopStatusChange,
      transportStatusChange,
      pageTabs,
      routerMenu,
      handleToCenter,
      handleLogout,
      handleChangePass,
      handleToSettings,
      newsVisible,
      openNews,
      countNum,
      newChange
    }
  },
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    SettingOutlined,
    ExportOutlined,
    DownOutlined,
    SelectLang,
    FullScreen,
    MenuTabs,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    DashboardOutlined,
    KeyOutlined,
    ClusterOutlined,
    ShopOutlined,
    ContactsOutlined,
    ChangePass,
    IconFont,
  }
}
</script>
