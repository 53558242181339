export default {
  submit: '提交',
  save: '保存',
  'submit.ok': '提交成功',
  'save.ok': '保存成功',
  fullscreen: '全屏',
  'fullscreen.ok': '退出全屏',
  columnTitle: '列展示',
  columnReset: '重置',
  tabsBar: {
    closeLeft: '关闭左边',
    closeRight: '关闭右边',
    closeOther: '关闭其它',
    closeAll: '全部关闭'
  }
}
