<template>
  <div class="navBox">
    <div class="flex al-center wrap">
      <div class=" m-b1">
        <slot name="customFront" />
      </div>
      <div v-for="item in iptData" :key="item.id" class="m-r1 m-b1" :style="{ width: item.width}">
        <div v-if="item.type == 'cascader'">
          <a-cascader style="width:100%"  allowClear  :change-on-select="item.changeOn" :field-names="{ value: item.valueType || 'value' }" :getPopupContainer="(triggerNode) => { return triggerNode.parentNode;}" v-model:value="fromOption[item.prop]" :options="item.opt" :placeholder="item.placeholder" />
        </div>
        <div v-if="item.type == 'selectTime'">
          <a-date-picker style="width:100%" v-model:value="fromOption[item.prop]" :placeholder="item.placeholder" />
        </div>
        <div v-if="item.type == 'selectTimeYM'">
          <a-month-picker :allowClear="item.allowClear ? false : true" style="width:100%"v-model:value="fromOption[item.prop]" :placeholder="item.placeholder" />
        </div>
        <div v-if="item.type == 'select'">
          <a-select style="width:100%" ref="select" allowClear v-model:value="fromOption[item.prop]" :placeholder="item.placeholder">
            <a-select-option v-for="opt in item.opt"  :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
        </div>
        <div v-if="item.type == 'address'">
          <a-cascader style="width:100%" :change-on-select="item.changeOn"  :show-search="{ filter }" v-model:value="fromOption[item.prop]" :options="$store.state.app.cityDict"   :placeholder="item.placeholder" />
        </div>
        <div v-if="item.type == 'selectRelevance'" class="flex">
          <a-select ref="select" v-model:value="fromOption[item.prop1]" style="width:100%"  :placeholder="item.placeholder1" @change="seleAdd1(fromOption[item.prop1], item)">
            <a-select-option v-for="opt in item.opt1" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
          <div class="m-l1" v-if="item.opt2.length > 0">
            <a-select ref="select" v-model:value="fromOption[item.prop2]" style="width:100%"  :placeholder="item.placeholder2">
              <a-select-option v-for="opt in item.opt2" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div  v-if="item.type == 'input'">
          <a-input style="width:100%"   v-model:value="fromOption[item.prop]" allowClear :placeholder="item.placeholder" >
            <template #prefix>{{ item.prefix }}</template>
          </a-input>
        </div>
        <div  v-if="item.type == 'range'">
          <a-range-picker style="width:100%"  :placeholder="['开始时间', '结束时间']" v-model:value="fromOption[item.prop]" />
        </div>
      </div>
      <div class=" m-b1 m-r1">
        <slot name="customFooter" />
      </div>
      <div class="btnBox m-b1">
        <ly-button type="primary" class="m-r1" @click="searchBtn">{{searchTitle}}</ly-button>
        <ly-button v-if="isShowReset" type="default" class="m-r1" @click="resetBtn">重置</ly-button>
      </div>
      <div class="btnBox m-b1">
        <slot name="tail" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
const props = defineProps({
  iptData: {
    type: Array,
    default: () => [],
  },
  isShowReset: {
    type: Boolean,
    default: true,
  },
  searchTitle: {
    type: String,
    default: '搜索',
  }
});
const emit = defineEmits(['searchAdd', 'resetBtn']) //搜索、重置事件
const fromOption = ref({})
const setValue = () => {
  let option = {};
  props.iptData.map((item) => {
    if (item.prop1) {
      option[item.prop1] = item.value1;
    }
    else if (item.prop2) {
      option[item.prop2] = item.value2;
    } else {
      option[item.prop] = item.value;
    }

  });
  fromOption.value = option
}
//搜索
const searchBtn = () => {
  emit('searchAdd', fromOption.value)
}
//第一项选择
const seleAdd1 = (val, item) => {
  fromOption.value[item.prop2] = null;
  emit('seleAdd1', val)
}
// 重置
const resetBtn = () => {
  setValue()
  emit('resetBtn')
}
watch(() => props.iptData.map(item => item.value), () => {
  setValue()
})
//时间比较清除
const resetTime = () => {
  fromOption.value.startTime = null
  fromOption.value.endTime = null
}
defineExpose({
  resetTime,
})
onMounted(() => {
  setValue()
})
</script>

<style lang="less" scoped>
.navBox {
  padding: 20px 10px 10px 10px;
  background: #ffffff;
}

:deep(.ant-cascader-menu) {
  &::-webkit-scrollbar {
    width: 6px; /* 控制滚动条的宽度 */
  }
  &::-webkit-scrollbar-thumb {
    background: #f6f6f6; /* 修改滚动条滑块颜色 */
    border-radius: 10px;
    height: 8px;
  }
}
</style>
