import { BasicLayout } from '@/layouts'

// 不作为Main组件的子页面展示的页面单独写，如下
const loginRouter = {
  path: '/login',
  name: 'login',
  meta: {
    title: 'menu.login'
  },
  component: () => import('@/views/login')
}

export const page404 = {
  path: '/404',
  name: 'error_404',
  meta: {
    title: '404-页面不存在'
  },
  component: () => import('@/views/exception/404.vue')
}

export const page403 = {
  path: '/403',
  name: 'error-403',
  meta: {
    title: '403-权限不足'
  },
  component: () => import('@/views/exception/403.vue')
}

export const page500 = {
  path: '/500',
  name: 'error-500',
  meta: {
    title: '500-服务端错误'
  },
  component: () => import('@/views/exception/500.vue')
}

// export const locking = {
//   path: '/locking',
//   name: 'locking',
//   component: () => import('@/views/lockscreen/components/locking-page.vue')
// }

// 作为Main组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里
export const baseRouter = {
  path: '/',
  name: 'index',
  redirect: 'home', 
  component: BasicLayout,
  children: [
    { path: '/home', meta: { title: 'menu.home'}, name: 'home', component: () => import('@/views/home') },
  
    // { path: '/editNew/:id', name: 'editNew', component: () => import('@/views/marketing/modList/editNew'), meta: { title: '配置新页面', keepAlive: false } },
    // { path: '/edit/:templateId/:id', name: 'modEdit', component: () => import('@/views/marketing/modList/Edit'), meta: { title: '编辑模板', keepAlive: false } },
    // { path: 'ownspace', title: '个人中心', name: 'ownspace', component: () => import('@/view/own-space/own-space.vue') },
    // { path: 'change-pass', title: '修改密码', name: 'change', component: () => import('@/view/change-pass/change-pass.vue') }
    // { path: 'message', title: '消息中心', name: 'message_index', component: () => import('@/view/message/message.vue') },
    { path: '/orderAdd', name: 'orderAdd', component: () => import('@/views/order/internal/Add'), meta: { title: 'menu.order.internal', keepAlive: false } },
    // { path: '/orderEdit/:id', name: 'orderEdit', component: () => import('@/views/order/internal/Edit'), meta: { title: 'menu.order.internal.edit', keepAlive: false } },
    // { path: '/orderAppletEdit/:id', name: 'orderAppletEdit', component: () => import('@/views/order/internal/appletEdit'), meta: { title: 'menu.order.internal.edit', keepAlive: false } },
    { path: '/orderDetail', name: 'orderDetail', component: () => import('@/views/order/internal/details'), meta: { title: 'menu.order.internal.detail', keepAlive: false } },
    // { path: '/customer/:id/orderAdd', name: 'customerOrder', component: () => import('@/views/crm/customer/AddOrder.vue'), meta: { title: 'menu.crm.customer.addOrder', keepAlive: true } },
    // { path: 'message-send-detail', title: '消息发送详情', name: 'message_send_detail', component: () => import('@/views/sys/message-manage/messageSendDetail.vue') }
    // { path: '/positionInfo/vehicleDetail/:orderId', name: 'vehicleDetail', component: () => import('@/views/order/positionInfo/detail.vue'), meta: { title: 'menu.order.positionInfo.detail', keepAlive: false } },
    // { path: '/crmManagement/relevance', name: 'relevance', component: () => import('@/views/crmManagement/relevance/index.vue'), meta: { title: 'menu.crmManagement.relevance', keepAlive: false } } // 待删// 待删
  ]
}

export const routers = [
  loginRouter,
  baseRouter,
  // locking,
  page500,
  page404,
  page403
]
