import { createI18n } from 'vue-i18n'
import moment from 'moment'

// default lang
import zhCN from './lang/zh-CN'

// 根据浏览器信息自动设置语言
const navLang = navigator.language

const localLang = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false

const defaultLang = window.localStorage.lang || localLang || 'zh-CN'

const messages = {
  'zh-CN': {
    ...zhCN
  }
}

const i18n = createI18n({
  locale: defaultLang,
  messages
})

const loadedLanguages = [defaultLang]

// i18n切换
function setI18nLanguage (lang) {
  i18n.global.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang = defaultLang) {
  return new Promise(resolve => {
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        return require([`./lang/${lang}`], (msg) => {
          const locale = msg.default
          i18n.global.setLocaleMessage(lang, locale)
          loadedLanguages.push(lang)
          moment.updateLocale(locale.momentName, locale.momentLocale)
          return setI18nLanguage(lang)
        })
      }
      return resolve(setI18nLanguage(lang))
    }
    return resolve(lang)
  })
}

export default i18n
