import axios from 'axios'
import storage from 'store'
import router from '@/router'
import {
  message
} from 'ant-design-vue'
import jsonlint from './jsonlint'

const base = process.env.NODE_ENV === 'mock' ? 'http://uat.56pai.cn/' : (process.env.NODE_ENV === 'prod' ? 'https://prod.56pai.cn/' : 'http://192.168.110.126:32223')  // 137 206

const filedBase = process.env.NODE_ENV === 'mock' ? 'http://uat.56pai.cn/api/file' : (process.env.NODE_ENV === 'prod' ? 'https://file.56pai.cn/api' : 'http://192.168.110.126:32223/file') // 137 206
// 超时设定
axios.defaults.timeout = 15000
axios.interceptors.request.use(config => {
  if (config.headers.authorization === undefined || config.headers.authorization === '') {
    router.push({ name: 'login' })
  }
  return config
}, err => {
  // Message.error('请求超时')
  return Promise.resolve(err)
})

// http response 拦截器
axios.interceptors.response.use(response => {
  const data = response.data
  // 根据返回的code值来做不同的处理(和后端约定)
  switch (data.code) {
    case 10001:
      // 未登录 清除已登录状态 清除锁屏状态
      if (router.currentRoute.name !== 'login') {
        if (data.msg !== null) {
          message.error(data.msg)
        } else {
          message.error('业务错误')
        }
      }
      break
    case 10002:
      // FAILURE_EXCEPTION
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('系统错误')
        return
      }
      break
    case 10010:
      // PARAM_VALID_ERROR
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('参数校验失败')
      }
      break
    case 10100:
      // MSG_NOT_READABLE
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('消息不可读')
      }
      break
    case 10101:
      // UN_AUTHORIZED
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('登录超时，请重新登录')
      }
      storage.remove('accessToken')
      router.push('login')
      break
    case 10102:
      // TOKEN_EXPIRED
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('登录超时，请重新登录')
      }
      storage.remove('accessToken')
      router.push('login')
      break
    case 10020:
      // PASSWORD_ERROR
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('密码必须修改')
      }
      break
    case 10030:
      // VERSION_ERROR
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('接口版本升级')
      }
      break
    case 10403:
      // REQ_REJECT
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('非法请求')
      }
      break
    case 10404:
      // NOT_FOUND
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('未找到请求资源')
      }
      break
    case 10405:
      // METHOD_NOT_SUPPORTED
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('不允许使用该请求方法')
      }
      break
    case 10406:
      // TOKEN_DELETE
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('用戶在其他地方登录')
      }
      storage.remove('accessToken')
      setTimeout(()=>{
        router.push('login')
      },500)
      break
    case 10407:
      // TOKEN_LOCKED
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('用户已锁定')
      }
      break
    case 10415:
      // MEDIA_TYPE_NOT_SUPPORTED
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('不支持的媒体类型')
      }
      break
    case 10500:
      // INTERNAL_SERVER_ERROR
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('服务繁忙')
      }
      break

    case 20000:
      // SETTLE_NO_PROTOCOL
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('业务异常')
      }
      break
    case 30000:
      // SERVICE_NOT_FOUND
      if (data.message !== null) {
        message.error(data.msg)
      } else {
        message.error('服务不在线')
      }
      break

    default:
      return data
  }
  return data
}, (err) => {

  // 返回状态码不为200时候的错误处理
  //   Message.error(err.toString())
  if (err.response) {
    switch (err.response.status) {
      case 429:
        message.error('页面请求次数过多,请稍等片刻')
        break
    }
  }

  return Promise.resolve(err)
})

export const transEnvUrl = (url) => {
  if (process.env.NODE_ENV === 'mock') {
    url = 'api' + url
  } else if (process.env.NODE_ENV === 'prod') {
    url = 'api' + url
  } else if (process.env.NODE_ENV === 'dev') {
    // if (url.indexOf('/') === 0) {
    //   url = url.replace('/', '')
    // }
  }
  return url
}

export const getRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'get',
    url: `${base}${url}`,
    params: params,
    timeout: 15 * 1000,
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }],
    headers: {
      'Content-Type': 'application/json',
      authorization: accessToken
    }
  })
}

export const postRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    timeout: 15 * 1000,
    transformRequest: [function (data) {
      let ret = ''
      for (const it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      ret = ret.substring(0, ret.length - 1)
      return ret
    }],
    headers: {
      authorization: accessToken
    }
  })
}

export const postFileRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    // transformRequest: [function (data) {
    //   return data
    // }],
    headers: {
      authorization: accessToken
    }
  })
}
export const putRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'put',
    url: `${base}${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json',
      authorization: accessToken
    }
  })
}
export const postBodyRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }],
    headers: {
      'Content-Type': 'application/json',
      authorization: accessToken
    }
  })
}
export const deleteBodyRequest = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'delete',
    url: `${base}${url}`,
    data: params,
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }],
    headers: {
      'Content-Type': 'application/json',
      authorization: accessToken
    }
  })
}
export const postBodyRequestSpecial = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    timeout: 30000,
    onUploadProgress (progressEvent) { // 实时上传进度
      // console.log((progressEvent.loaded / progressEvent.loaded * 100).toFixed(0))
      params.start = (progressEvent.loaded / progressEvent.loaded * 100).toFixed(0)
    },
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }],
    headers: {
      'Content-Type': 'application/json',
      authorization: accessToken
    }
  })
}
export const postBodyRequestPdf = (url, params) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }],
    headers: {
      'Content-Type': 'application/pdf',
      authorization: accessToken
    }
  })
}
export const exportRequest = (url, params, fileName) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  const name = fileName
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    responseType: 'blob',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: accessToken
    }
  }).then(
    response => {
      const blob = new Blob([response], {
        type: 'application/vnd.ms-excel'
      })
      const now = new Date()
      const fileName = name + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '-' + now.getHours() + '.xlsx'
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        // 释放内存
        window.URL.revokeObjectURL(link.href)
      }
    },
    err => {
      console.log(err)
    }
  )
}
export const exportRequestImg = (url, params, fileName) => {
  const accessToken = 'Bearer ' + storage.get('accessToken')
  const name = fileName
  return axios({
    method: 'POST',
    url: filedBase + url,
    data: params,
    responseType: 'blob',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: accessToken
    }
  }).then(
    response => {
      const blob = new Blob([response])
      const fileName = name + '.jpg'
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        // 释放内存
        window.URL.revokeObjectURL(link.href)
      }
    },
    err => {
      console.log(err)
    }
  )
}
/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url
 * @param {*} params
 */
export const getNoAuthRequest = (url, params) => {
  url = transEnvUrl(url)
  return axios({
    method: 'get',
    url: `${base}${url}`,
    params: params,
    timeout: 5000
  })
}
export const postNoAuthRequest = (url, params) => {
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    timeout: 5000
  })
}
// 图片上传
export const fileUpload = (url, params) => {
  return axios({
    method: 'post',
    url: filedBase + url,
    data: params,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: 'Bearer ' + storage.get('accessToken')
    },
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          return objData
        } catch (e) {
          console.log(e)
        }
      } 
      return data
    }]
  })
}
// 图片上传 
export const fileRealUpload = (url, params) => {
  url = transEnvUrl(url)
  return axios({
    method: 'post',
    url:`${base}${url}`,
    data: params,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: 'Bearer ' + storage.get('accessToken')
    },
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      if (data && typeof data === 'string') {
        try {
          const objData = jsonlint.parse(data)
          // objData.data.url = objData.data.url.split('?')[0]
          return objData
        } catch (e) {
          console.log(e)
        }
      }
      return data
    }]
  })
}

