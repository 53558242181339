import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 待发运小车
export function transportPage (params) {
  return postBodyRequest('/cs/order/vehicle/transport-page', params)
}
// 可发运大车
export function operationShip (params) {
  return postBodyRequest('/cs/transport/operation-ship', params)
}
// 调度车辆信息
export function transportVehicle (orderVehicleId) {
  return getRequest(`/cs/order/vehicle/transport-vehicle/${orderVehicleId}`)
}
// 车辆图片

export function vehicleImage (orderVehicleId, params) {
  return postBodyRequest(`/cs/order/vehicle-inspect/${orderVehicleId}/page`, params)
}
// 上传--车辆检查图片
export function uploadVehicleInspect (orderVehicleId,params) {
  return postBodyRequest(`/cs/order/vehicle-inspect/${orderVehicleId}/file`, params)
}
// 删除图片
export function uploadVehicleDelete(orderVehicleInspectId) {
  return postBodyRequest(`/cs/order/vehicle-inspect/${orderVehicleInspectId}/delete`, )
}
// 新增发运
export function transportAssembled(params) {
  return postBodyRequest(`/cs/transport/assembled`,params )
}
// 装车
export function transportmind(params) {
  return postBodyRequest(`/cs/transport/load-up`,params )
}
// 中途补位-小车
export function transportLoadUp(params) {
  return postBodyRequest(`/cs/transport/cover-pit`,params )
}
// 发车
export function transportDepart(params) {
  return postBodyRequest(`/cs/transport/depart`,params )
}
// 运输任务详情
export function transportInfo(transportId) {
  return getRequest(`/cs/transport/${transportId}` )
}
// 运输任务-位置-分页
export function transportGpsPage(transportId) {
  return getRequest(`/cs/transport/${transportId}/gps-page` )
}
// 运输任务新增地理位置
export function transportGpsAdd(params) {
  return postBodyRequest(`/cs/transport/gps-add`,params )
}
// 到达
export function transportArrival(params) {
  return postBodyRequest(`/cs/transport/arrival`,params )
}

// 承运商线路-分页
export function carrierLinePage(params) {
  return postBodyRequest(`/cs/hy/carrier/line/page`,params )
}
// 承运商线路-查看
export function carrierLineLook(lineId) {
  return getRequest(`/cs/hy/carrier/line/${lineId}` )
}
// 承运商线路-编辑
export function carrierLineEdit(params) {
  return postBodyRequest(`/cs/hy/carrier/line/edit`,params )
}

// 发运任务-分页
export function transportAllPage(params) {
  return postBodyRequest(`/cs/transport/all-page`,params )
}
// 运输-归档查看
export function vehicleTruckOnFile(transTruckId) {
  return getRequest(`/cs/transport/${transTruckId}/onfile` )
}
// 退板
export function vehicleBackPlate(params) {
  return putRequest(`/cs/transport-ops/back-plate`,params )
}

// 发运任务
export function orderTaskPage(params) {
  return postBodyRequest(`/cs/hy/order-task/page`,params )
}
// 任务接单或拒单
export function orderTaskDoTask(params) {
  return postBodyRequest(`/cs/hy/order-task/do-task`,params )
}
// 修改发运费用
export function vehicleChangeFee(params) {
  return putRequest(`/cs/transport-ops/change-fee`,params )
}

// 任务统计
export function orderTaskCount(params) {
  return getRequest(`/cs/hy/order-task/count`,params )
}
// 任务付款码
export function orderpayqrcodeTask (params) {
  return postBodyRequest(`/cs/orderpay/qrcode/task`, params)
}
// 编辑运单
export function transportOpsEdit(params) {
  return putRequest(`/cs/transport-ops/edit`,params)
}

// 在途跟踪模块
export function transitList (params) {
  return postBodyRequest(`/cs/transport/in-transit`, params)
}