<template>
  <div v-if="visible" @click="closeLy" class="lyoutBox flex ju-center al-center">
    <div class="toolbarBox flex al-center ">
      <div style="width: 100px;" class="flex ju-between ">
        <span class="cur-p">
          <RotateLeftOutlined v-if="imgList[0]?.mimeType !== 'pdf'" @click="InOut" style="color:#fff;font-size:20px " />
        </span>
        <span class="cur-p">
          <RotateRightOutlined v-if="imgList[0]?.mimeType !== 'pdf'" @click="OutOut" style="color:#fff;font-size:20px " />
        </span>
        <span @click="close" class="cur-p">
          <CloseOutlined style="color:#fff;font-size:20px " />
        </span>
      </div>
    </div>
    <template v-if="imgList[0]?.mimeType == 'pdf'">
      <iframe :src="imgList[0].url" :style="iframeStyle"></iframe>
    </template>
    <template v-else>
      <div class="leftIcon" v-if="imgList.length > 1" @click="leftADD" :class="idx==0?'noDrop':'cur-p'">
        <LeftCircleOutlined style="font-size:36px;color:#fff" />
      </div>
      <div style="width:100%;height:100vh;" class="pos-rel " ref="imgWrap" @mousewheel.prevent="rollImg">
        <div>
          <div v-for="(item,index) in imgList" :key="item.id">
            <div class="pos-abs" style="left:50%;top:50%; transform: translate(-50%, -50%);z-index:999">
              <img @mousedown.prevent="moveImg" ref="imgRef" v-if="idx == index" style="z-index: 99;cursor:move;max-height:100vh" class="pos-rel" :src="item.url" />
            </div>
          </div>
        </div>
        <div class="Imgbox" @click="close">
        </div>
      </div>
      <div class="rightIcon" v-if="imgList.length > 1" @click="rightADD" :class="idx==imgList.length-1?'noDrop':'cur-p'">
        <RightCircleOutlined style="font-size:36px;color:#fff" />
      </div>
    </template>

  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { CloseOutlined, LeftCircleOutlined, RightCircleOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons-vue';
import { abnormalImgs } from '@/api/utils'
import { message } from 'ant-design-vue';
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookImgID: {
    type: Array,
    default: []
  }
})
const iframeStyle = ref({
  width: '80%',
  height: '90%'
})
const imgList = ref([])
const idx = ref(0)
const emit = defineEmits(['update:visible'])
const imgRef = ref(null)
const degree = ref(0)
//向左旋转
const InOut = () => {
  degree.value = degree.value - 90
  imgRef.value.style.transform = `rotate(${degree.value}deg)`
}
const closeLy = () => {
  if (imgList.value[0]?.mimeType === 'pdf') {
    close()
  }
}
const OutOut = () => {
  degree.value = degree.value + 90
  imgRef.value.style.transform = `rotate(${degree.value}deg)`
}
const rollImg = (e) => {
  let transform = imgRef.value.style.transform
  let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1
  zoom += e.wheelDelta / 1200
  if (zoom > 0.1 && zoom < 3.2) {
    imgRef.value.style.transform = "scale(" + zoom + ")" + `rotate(${degree.value}deg)`
  }
}
//移动
const imgWrap = ref(null)
const moveImg = (e) => {
  let wrap = imgWrap.value
  let img = imgRef.value
  let x = e.pageX - img.offsetLeft
  let y = e.pageY - img.offsetTop
  // 添加鼠标移动事件
  wrap.addEventListener('mousemove', move)
  function move (e) {
    img.style.left = e.pageX - x + 'px'
    img.style.top = e.pageY - y + 'px'
  }
  // 添加鼠标抬起事件,鼠标抬起,将事件移除
  img.addEventListener('mouseup', () => {
    wrap.removeEventListener('mousemove', move)
  })
  // 鼠标离开父级元素,把事件移除
  wrap.addEventListener('mouseout', () => {
    wrap.removeEventListener('mousemove', move)
  })
}

const leftADD = () => {
  if (idx.value == 0) return
  degree.value = 0
  idx.value--
}
const rightADD = () => {
  if (idx.value == imgList.value.length - 1) return
  degree.value = 0
  idx.value++
}
const close = () => {
  imgList.value = []
  emit('update:visible', false)
}
const loading = ref(false)
let Imgbox = ['pdf', 'bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng']
const reqAbnormalImgs = () => {
  loading.value = true
  abnormalImgs({
    fileIds: props.lookImgID
  }).then((res) => {
    loading.value = false
    if (res.code == 10000) {
      if (res.data.every(item => Imgbox.includes(item.mimeType))) {
        imgList.value = res.data
      } else {
        close()
        for (let i = 0; i < res.data.length; i++) {
          let windowName = "downloadWindow" + i;
          window.open(res.data[i].url, windowName);
        }
      }

    } else {
      message.error(res.msg)
      close()
    }
  })
}
watch(() => props.visible, (val, old) => {
  if (val) {
    reqAbnormalImgs()
  } else {
    idx.value = 0
  }
})
</script>

<style lang="less" scoped>
.lyoutBox {
  width: 100%;
  height: 100vh;
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
}

.toolbarBox {
  position: absolute;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  height: 50px;
  width: 100%;
  padding: 0 20px;
  justify-content: flex-end;
}

.leftIcon {
  position: absolute;
  left: 50px;
  top: 50%;
  z-index: 999;
}

.rightIcon {
  position: absolute;
  right: 50px;
  top: 50%;
  z-index: 999;
}
.noDrop {
  cursor: no-drop;
  opacity: 0.5;
}
.Imgbox {
  width: 100%;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
</style>