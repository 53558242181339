import { createStore } from 'vuex'

import user from './modules/user'
import app from './modules/app'
import enumAll from './modules/enumAll'
import staticConfig from './modules/staticConfig'

import getters from './getters'

// default router permission control
import permission from './modules/permission'

export default createStore({
  modules: {
    user,
    app,
    permission,
    enumAll,
    staticConfig
  },
  getters
})
