<template>
  <div>
    <a-modal :visible="showModal" title="车辆图片" footer="" @cancel="cancel" width="31%">
      <ly-batchUpload :orderVehicleId="orderVehicleId" @success="success"></ly-batchUpload>
      <div>
        <div class="flex ju-between">车辆图片 <a-button v-if="imgList.length > 0" size="small" type="primary" @click="editAdd"><span v-if="!isEdit">编辑</span><span v-else>查看</span></a-button></div>
        <div v-show="!isEdit" class="flex  wrap" style="width:100%">
          <a-image-preview-group>
            <div v-for="form in imgList" :key="form.id" class="upload-image">
              <a-image ref="imageRef" style="height:102px" width="100%"  :src="form.fileRealUrl" />
            </div>
          </a-image-preview-group>
        </div>
        <div v-show="isEdit" class="flex  wrap" style="width:100%">
          <div v-for="form in imgList" :key="form.id" class="upload-image">
            <div class="upload-image-info">
              <a-image ref="imageRef" style="height:102px" width="100%" :preview="form.preview" :src="form.fileUrl" />
            </div>
            <div class="upload-image-actions">
              <span class="m-l1 cur-p" @click="handlePreview(form)">
                预览
              </span>
              <span class="m-l1 cur-p" @click="deleteImg(form)">删除</span>
            </div>
          </div>
        </div>
        <div class="m-t1 flex ju-end">
          <a-pagination v-model:current="pagination.current" @change="change" :total="pagination.total" show-less-items />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { vehicleImage, uploadVehicleDelete } from '@/api/transport/vehicle'
import { message } from 'ant-design-vue';
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  orderVehicleId: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:showModal', 'success'])
const imgList = ref([])
const isEdit = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0
})

const editAdd = () => {
  isEdit.value = !isEdit.value
}

const change = (e) => {
  pagination.value.current = e
  reqVehicleImage()
}
const cancel = () => {
  emit('update:showModal', false)
}
//删除图片
const deleteImg = (item) => {
  uploadVehicleDelete(item.id).then(res => {
    if (res.code !== 10000) return
    message.success('删除成功')
    emit('success')
    reqVehicleImage()
  })
}
const handlePreview = (item) => {
  item.preview = {
    visible: true,
    onVisibleChange: (visible, prevVisible) => {
      !visible && (item.preview = visible)
    }
  }
  item.fileUrl = item.fileRealUrl
}
const reqVehicleImage = () => {
  vehicleImage(props.orderVehicleId, {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }).then(res => {
    console.log(res)
    imgList.value = res.data.records
    pagination.value.total = res.data.total
    imgList.value.forEach(item => {
      item.preview = false
    })
  })
}
const success = () => {
  emit('success')
  reqVehicleImage()
}
onMounted(() => {
  reqVehicleImage()
})
</script>

<style lang="less" scoped>
.upload-image {
  position: relative;
  border: 1px solid #eee;
  width: 102px;
  height: 102px;
  margin-right: 5px;
  margin-bottom: 10px;

  &-info {
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: all 0.3s;
      content: ' ';
    }
  }

  &:hover {
    .upload-image-actions,
    .upload-image-info::before {
      opacity: 1;
    }
  }

  &-actions {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 100%;
    transition: all 0.3s;
  }
}
</style>