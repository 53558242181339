import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'
// 承运商主体新增
export function bodyAdd (params) {
  return postBodyRequest('/cs/body/add', params)
}
// 承运商主体分页
export function page (params) {
  return postBodyRequest('/cs/body/page', params)
}
// 承运商主体 启用-停用
export function isEnable (id) {
  return getRequest(`/cs/body/is-enable?id=${id}`, )
}
// 承运商主体详情
export function bodyDetail(id) {
  return getRequest(`/cs/body/detail?id=${id}`)
}
// 承运商主体名字编辑
export function bodyNameEdit (params) {
  return postBodyRequest('/cs/body/body-name/edit', params)
}
// 承运商主体编辑
export function bodyEdit (params) {
  return postBodyRequest('/cs/body/edit', params)
}
// 承运商主体list
export function bodyList (params) {
  return postBodyRequest('/cs/body/list', params)
}